import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';


const rootEl = document.getElementById('verein-mini-homepage');
const dataClubId = rootEl.getAttribute('data-club-id');
const dataIsHomepage = rootEl.getAttribute('data-is-homepage');



ReactDOM.render(
  <React.StrictMode>
    <App dataClubId={ dataClubId } isHomepage={!!dataIsHomepage && dataIsHomepage === "true"} />
  </React.StrictMode>,
  rootEl
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
