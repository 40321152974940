import React from 'react';
import axios from 'axios';
import moment from 'moment';

const idApiUrlsMap = {
    15: 'https://www.nbmb-verwaltung.de/',
    19: 'https://www.bvv-verwaltung.de/',
    13: 'https://www.asm-verwaltung.de/',
    14: 'https://www.mon-verwaltung.de/'
};

const dayMap = {
    'Mo': 'Montag', 
    'Tu': 'Dienstag', 
    'We': 'Mittwoch', 
    'Th': 'Donnerstag', 
    'Fr': 'Freitag', 
    'Sa': 'Samstag', 
    'Su': 'Sonntag'
};

const instrumentenLinksMap = {
    7: 'https://blasmusik4u.de/instrumente/querfloete/',
    8: 'https://blasmusik4u.de/instrumente/klarinette/',
    9: 'https://blasmusik4u.de/instrumente/oboe/',
    10: 'https://blasmusik4u.de/instrumente/fagott/',
    11: 'https://blasmusik4u.de/instrumente/saxophon/',
    12: 'https://blasmusik4u.de/instrumente/trompete/',
    13: 'https://blasmusik4u.de/instrumente/horn/',
    14: 'https://blasmusik4u.de/instrumente/posaune/',
    15: 'https://blasmusik4u.de/instrumente/tenorhorn-bariton-euphonium/',
    16: 'https://blasmusik4u.de/instrumente/tuba/',
    17: 'https://blasmusik4u.de/instrumente/schlagzeug/',
    18: 'https://blasmusik4u.de/instrumente/spielmannsfloete/',
    19: 'https://blasmusik4u.de/instrumente/fanfare/',
    20: 'https://blasmusik4u.de/instrumente/naturhorn-alphorn/',
    22: 'https://blasmusik4u.de/blog/die-blaeserklasse-von-der-ersten-stunde-an-zusammen-musizieren/',
    23: 'https://blasmusik4u.de/instrumente/tischharfe/'
    // 21">Sonstige Instrumente</option>
}

// Test ID: 15027A046

const DetailPage = ({ dataClubId, isHomepage }) => {
    const [ data, setData ] = React.useState(undefined);
    const [ logo, setLogo ] = React.useState(undefined);
    const [ images, setImages ] = React.useState([]);
    const [ offers, setOffers ] = React.useState({ targetGroup: [], instruments: [] });
    const [ events, setEvents ] = React.useState(undefined);
    const [ news, setNews ] = React.useState(undefined);
    const [ activeTab, setActiveTab ] = React.useState('Aktuelles');
    const [ expandedDescriptions, setExpandedDescriptions ] = React.useState([]);

    const getGlubData = async (clubId) => {
        const url = idApiUrlsMap[clubId.substring(0, 2)];
        axios.get(`${url}api/public/clubs/${clubId}`)
            .then(res => {
                if (res.status && res.status === 200) {
                    if (res.data && res.data.item) {
                        if (res.data.item.images && res.data.item.images.length) {
                            // logo
                            const logo = res.data.item.images.find(image => image.logo);
                            if (logo) {
                                setLogo(logo);
                            }
                            // other images
                            const images = res.data.item.images.filter(image => !image.logo);
                            setImages(images);
                        }
                        
                        // Ausbildungsangebote
                        if (res.data.item.trainingOffers) {
                            const initOffers = { ...offers };
                            const offersTargetGroup = res.data.item.trainingOffers.find(trainingOffersType => trainingOffersType.name === 'Elementare Musikausbildung');
                            if (offersTargetGroup) {
                                initOffers.targetGroup = offersTargetGroup.offers;
                                initOffers.targetGroup.sort((a, b) => (a.id > b.id) ? 1 : -1);
                            }
                            const offersInstrument = res.data.item.trainingOffers.find(trainingOffersType => trainingOffersType.name === 'Instrumentalausbildung');
                            if (offersInstrument) {
                                initOffers.instruments = offersInstrument.offers;
                                initOffers.instruments.sort((a, b) => (a.id > b.id) ? 1 : -1);
                            }
                            setOffers(initOffers);
                        }
                        
                        setData(res.data.item);
                    }
                }
            });
    };

    const getClubEvents = async (clubId) => {
        const url = idApiUrlsMap[clubId.substring(0, 2)];
        const end = moment().add(2, 'y').format('YYYY-MM-DD')
        axios.get(`${url}api/public/events?club=${clubId}&includeFullInfo=1&end=${end}`)
            .then(res => {
                if (res.status && res.status === 200) {
                    if (res.data && res.data.itemSet) {
                        setEvents(res.data.itemSet);
                    }
                }
            });
    };

    const getClubNews = async (clubId) => {
        const url = idApiUrlsMap[clubId.substring(0, 2)];
        axios.get(`${url}api/public/news?club=${clubId}&includeFullInfo=1`)
            .then(res => {
                if (res.status && res.status === 200) {
                    if (res.data && res.data.itemSet) {
                        setNews(res.data.itemSet);
                    }
                }
            });
    };

    const getSocialMediaLink = (link, type) => {
        const types = {
            instagram: 'https://www.instagram.com/',
            facebook: 'https://www.facebook.com/',
            twitter: 'https://www.twitter.com/'
        };
        return link.includes('www') ? link : types[type] + link;
    };

    const getNewsItemImages = (images, textTooLong, expandedDescription) => {
        if (!images || !images.length) {
            return [];
        }
        if (!textTooLong) {
            return images;
        }
        if (!expandedDescription) {
            return [ images[0] ];
        }
        return images;
    }

    const setTab = (tab) => {
        setActiveTab(tab);
        const searchParams = new URLSearchParams(document.location.search.substring(1));
        searchParams.set('tab', tab);
        searchParams.delete('eventId');
        const newRelativePathQuery = window.location.pathname + '?' + searchParams.toString();
        window.history.pushState(null, '', newRelativePathQuery);
    }

    const handleExpandDescription = (newsItemId) => {
        let nextExpandedDescriptions;
        if (!expandedDescriptions.includes(newsItemId)) {
            nextExpandedDescriptions = [ ...expandedDescriptions, newsItemId ];
        }
        else {
            nextExpandedDescriptions = expandedDescriptions.filter(id => id !== newsItemId);
        }
        setExpandedDescriptions(nextExpandedDescriptions);
    };

    const getInitTab = () => {
        if (news && news.length) {
            return 'Aktuelles';
        }
        if ((data && data.description) || offers.targetGroup.length || offers.instruments.length) {
            return 'Details';
        }
        if (images && images.length) {
            return 'Bildergalerie';
        }
        if (events && events.length) {
            return 'Termine';
        }
        return 'Kontakt';
    };

    const init = async () => {
        const params = new URLSearchParams(document.location.search.substring(1));

        let clubId = dataClubId; // Default by attribute

        if (!dataClubId) { // otherwise URL Param
            clubId = params.get("verein");
        }

        // set init tab
        /*
        const tab = params.get("tab");
        if (tab) {
            if (!['Details', 'Aktuelles', 'Bildergalerie', 'Termine', 'Kontakt'].includes(tab)) {
                setActiveTab('Aktuelles');
            }
            else {
                setActiveTab(initTab);
            }
        }
        */
       
        if (clubId) {
            getGlubData(clubId);
            getClubEvents(clubId);
            getClubNews(clubId);
        }
    }

    React.useEffect(() => {
        init();
    }, []);

    React.useEffect(() => {
        if (news && events && data) {
            const params = new URLSearchParams(document.location.search.substring(1));
            const tab = params.get("tab");
            if (tab) {
                setActiveTab(tab);
            } else {
                const initTab = getInitTab();
                setActiveTab(initTab);
            }
        }
    }, [news, events, data]);

    React.useEffect(() => {
        if (events && events.length) {
            const params = new URLSearchParams(document.location.search.substring(1));
            const eventId =  params.get("eventId");
            setTimeout(() => {
                const event = document.getElementById(eventId);
                if (!!event) {
                    const rect = event.getBoundingClientRect();
                    const scrollTo = rect.top - 150;
                    window.scrollTo({
                        top: scrollTo,
                        left: 0,
                        behavior: 'smooth'
                    });
                }
            }, 2000);
        }
    }, [events]);

    if (!data) {
        return <></>;
    }

    return (
        <>
        
            {/* **************************** header.php *****************************/}
            <div class="b4u-header uk-flex uk-grid-collapse uk-grid">
                {/* columne 1*/}
                <div class="uk-width-expand">
                    <div class="b4u-main-item">
                        <h1 class="b4u-main-item-name">{ data.name }</h1>
                    </div>
                </div>
                {/* columne 1 ENDE*/}
                {/* columne 2*/}
                <div class="uk-flex-first uk-flex-last uk-width-1-4"> {/* UPDATE: uk-width-1-3@s in uk-width-1-4@s geändert*/}

                    {/* Logo oder Plakat: Bild mit Lightbox und Copyright-Overlay*/}
                    <div class="b4u-image-expandable uk-inline uk-transition-toggle" uk-lightbox="true">
                        { logo && 
                        <a href={ logo.url } data-type="image">
                            <img src={ logo.url } alt={ `Logo: ${data.name}` } />

                            {/* Copyright-Overlay optional - wenn vorhanden*/}
                            { logo.atuhor &&
                                <div class="copy">Foto: { logo.atuhor }</div>
                            }

                            {/* Lupe Overlay*/}
                            <div class="uk-position-center uk-transition-fade uk-light">
                                <span uk-icon="icon: search; ratio: 2"></span>
                            </div>

                        </a>
                        }
                    </div>
                </div>
                {/* columne 2 ENDE*/}
            </div>
            {/* **************************** header.php ENDE *****************************/}


            {/* Tabs*/}
            <ul uk-tab="true">
                <li className={ activeTab === 'Aktuelles' ? 'uk-active' : '' }><a href="#" onClick={ () => setTab('Aktuelles') }>Aktuelles</a></li>
                <li className={ activeTab === 'Details' ? 'uk-active' : '' }><a href="#" onClick={ () => setTab('Details') }>über uns</a></li>
                <li className={ activeTab === 'Bildergalerie' ? 'uk-active' : '' }><a href="#" onClick={ () => setTab('Bildergalerie') }>Bildergalerie</a></li>
                <li className={ activeTab === 'Termine' ? 'uk-active' : '' }><a href="#" onClick={ () => setTab('Termine') }>Termine</a></li>
                <li className={ activeTab === 'Kontakt' ? 'uk-active' : '' }><a href="#" onClick={ () => setTab('Kontakt') }>Kontakt</a></li>
            </ul>

            {/* Content*/}
            <ul class="uk-switcher uk-margin">
                <li id="Details" className={ activeTab === 'Details' ? 'uk-active' : '' }>

                    {/* **************************** b4u-detail.php *****************************/}
                    <div class="b4u-detail uk-background-muted uk-padding">
                        <div class="b4u-detail-info">
                        { data.description && 
                            <div class="b4u-detail-info" dangerouslySetInnerHTML={{ __html: data.description.replace(/\n/g, '<br />') }}></div>
                        }
                        </div>

                        {/* Angebote*/}
                        { Boolean(offers.targetGroup.length) &&
                        <div class="b4u-angebote uk-margin-medium-top">
                            <h2>Musikalische Angebote für Kinder bis ca. 8 Jahre</h2>
                            <ul class="b4u-badge-list">
                                { offers.targetGroup.map(offer => (
                                    <li>{ offer.name }</li>
                                ))}
                            </ul>
                        </div>
                        }

                        { !!offers.instruments.length && isHomepage &&
                        <div class="b4u-angebote uk-margin-medium-top">
                            <h2>Musikunterricht wird für folgende Instrumente erteilt</h2>
                            <ul class="b4u-badge-list">
                                { offers.instruments.map(offer => (
                                    <li>{ offer.name }</li>
                                ))}
                            </ul>
                        </div>
                        }

                        { !!offers.instruments.length && !isHomepage &&
                        <div class="b4u-angebote uk-margin-medium-top">
                            <h2>Musikunterricht wird für folgende Instrumente erteilt</h2>
                            <ul class="b4u-badge-list">
                            { offers.instruments.map(offer => (
                                instrumentenLinksMap[offer.id] ?
                                <li><a href={ instrumentenLinksMap[offer.id] } >{ offer.name }</a></li> :
                                <li>{ offer.name }</li>
                            ))}
                            </ul>
                        </div>
                        }

                    </div>

                    {/* **************************** b4u-detail.php ENDE *****************************/}
                </li>

                <li id="Bildergalerie" className={ activeTab === 'Bildergalerie' ? 'uk-active' : '' }>
                    {/* **************************** b4u-imagelist.php **************************** */}

                    <div class="b4u-imagelist b4u-gallery uk-background-muted uk-padding">

                        <div class="uk-grid-match uk-text-center uk-grid-medium uk-grid uk-child-width-1-2  uk-child-width-1-3@s uk-child-width-1-4@m uk-child-width-1-5@l" uk-lightbox="animation: slide;">

                            {/* b4u-gallery-image */}
                            { images.map(image => (
                            <div class="b4u-gallery-image">
                                <div class="uk-cover-container uk-display-inline-block b4u-image-square uk-transition-toggle">
                                    <a href={ image.url } data-type="image" data-caption={ `${ image.description } ${image.author ? `(Foto: ${image.author})` : '' }` }>
                                        <img src={ `${image.url}?size=256` } alt uk-cover="true" />
                                        {/* Copyright-Overlay optional - wenn vorhanden */}
                                            { image.author &&
                                            <div class="copy">Foto: { image.author }</div>
                                            }
                                        {/* Lupe Overlay */}
                                        <div class="uk-position-center uk-transition-fade uk-light">
                                            <span uk-icon="icon: search; ratio: 2"></span>
                                        </div>
                                    </a>
                                </div>
                                { image.description &&
                                <figcaption class="caption">{ image.description }</figcaption>
                                }
                            </div>
                            ))}
                            {/* b4u-gallery-image ENDE */}

                        </div>
                    </div>

                    {/* **************************** b4u-imagelist.php ENDE **************************** */}

                </li>

                <li id="Termine" className={ activeTab === 'Termine' ? 'uk-active' : '' }>

                    {/* **************************** b4u-eventlist.php **************************** */}
                    <div class="b4u-item-list">

                        { events && events.map(event => (
                        <div class="b4u-event-item uk-padding-small uk-margin-medium-bottom" id={event.id}>
                            <div class="uk-grid-medium uk-flex uk-grid" uk-grid="">
                                { event.image &&
                                <div class="b4u-event-item-image uk-width-1-5@m uk-flex-last@m">
                                    <div class="uk-inline uk-transition-toggle" uk-lightbox="true">
                                        <a href={ event.image.url } data-type="image">
                                            <img src={ event.image.url } alt={ event.image.description } />
                                            <div class="copy"></div>
                                            <div class="b4u-zoom uk-position-center uk-transition-fade uk-light">
                                                <span uk-icon="icon: search; ratio: 2" class="uk-icon"><svg width="40" height="40" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><circle fill="none" stroke="#000" stroke-width="1.1" cx="9" cy="9" r="7"></circle><path fill="none" stroke="#000" stroke-width="1.1" d="M14,14 L18,18 L14,14 Z"></path></svg></span>
                                            </div>
                                        </a>
                                    </div>
                                </div>
                                }
                                
                                <div class="b4u-event-item-info uk-width-1-1 uk-width-1-5@m uk-first-column">
                                    <ul>
                                        <li class="b4u-event-item-date">{ moment(event.startDate).format('DD.MM.YYYY') }</li>
                                        <li class="b4u-event-item-dayandtime">{ dayMap[moment(event.startDate).format('dd')] } | { moment(event.startDate).format('HH:mm') } Uhr</li>
                                        <li class="b4u-event-item-location">{ event.location }</li>
                                        {/* !!props.club.dist &&
                                        <li class="b4u-event-item-distance"><span uk-icon="icon: location; ratio: 0.75" class="uk-icon"><svg width="15" height="15" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fill="none" stroke="#000" stroke-width="1.01" d="M10,0.5 C6.41,0.5 3.5,3.39 3.5,6.98 C3.5,11.83 10,19 10,19 C10,19 16.5,11.83 16.5,6.98 C16.5,3.39 13.59,0.5 10,0.5 L10,0.5 Z"></path><circle fill="none" stroke="#000" cx="10" cy="6.8" r="2.3"></circle></svg></span>{ props.club.dist } km entfernt</li>
                                        */}
                                    </ul>
                                </div>
                                
                                <div class="b4u-event-item-event uk-width-1-1 uk-width-3-5@m">
                                    <h3 class="b4u-event-item-title">{ event.name }</h3>
                                    {/*<div class="b4u-event-item-club">{ clubData.name }</div>*/}
                                    { event.description &&
                                    <div class="b4u-event-item-description">
                                        <p dangerouslySetInnerHTML={{ __html: event.description.replace(/\n/g, '<br />')}}></p>
                                    </div>
                                    }
                                    <div class="b4u-event-item-info"></div>
                                    {/*<div class="b4u-event-item-link"><a href={`/unterrichts-finder/details/?verein=${clubData.clubId}&tab=Termine`} class="uk-button uk-button-primary">mehr erfahren<span uk-icon="chevron-right" class="uk-icon"><svg width="20" height="20" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><polyline fill="none" stroke="#000" stroke-width="1.03" points="7 4 13 10 7 16"></polyline></svg></span></a></div>*/}
                                </div>
                            </div>
                        </div>
                        ))}


                    </div>
                    {/* **************************** b4u-eventlist.php ENDE **************************** */}
                </li>

                <li id="Aktuelles" className={ activeTab === 'Aktuelles' ? 'uk-active' : '' }>
                    {/* **************************** b4u-newslist.php **************************** */}
                    <div class="b4u-item-list">

                        {/* news item start */}
                        { news && news.map(newsItem => (
                        <div class="b4u-item-container">
                            <div class="b4u-item uk-grid-collapse uk-grid-match uk-grid">

                                {/* column 1 + grid */}
                                <div class="b4u-item-column-1 uk-background-muted">
                                    <div class="uk-flex uk-grid-collapse uk-grid">

                                        {/* column 1-1  */}
                                        <div class="b4u-item-column-1-1 uk-width-expand@s">

                                            {/* event */}
                                            <div class="b4u-main-item">
                                                <h2 class="b4u-main-item-news">{ newsItem.title }</h2>
                                                <p class="b4u-main-item-news-published">veröffentlicht am { moment(newsItem.startDate).format('DD.MM.YYYY') }</p>

                                                {/* Detailinfo Veranstaltung */}
                                                { newsItem.content && newsItem.content.length > 600 && !expandedDescriptions.includes(newsItem.id) &&
                                                <div class="b4u-detail-info b4u-detail-info-visible">
                                                    <p 
                                                        dangerouslySetInnerHTML={{ __html: `${newsItem.content.substring(0, 600).replace(/\n/g, '<br />')}…` }}
                                                    >
                                                    </p>    
                                                </div>
                                                }

                                                { newsItem.content && (newsItem.content.length <= 600 || expandedDescriptions.includes(newsItem.id)) &&
                                                <div class="b4u-detail-info b4u-detail-info-visible">
                                                    <p 
                                                        dangerouslySetInnerHTML={{ __html: newsItem.content.replace(/\n/g, '<br />') }}
                                                    >
                                                    </p>    
                                                </div>
                                                }

                                                {/* Toggle Infos down */}
                                                { newsItem.content && newsItem.content.length > 600 &&
                                                <div class="b4u-button b4u-detail-toggle">
                                                    <a class="uk-button uk-button-primary" onClick={ () => handleExpandDescription(newsItem.id) }>{ !expandedDescriptions.includes(newsItem.id) ? 'alles anzeigen' : 'weniger anzeigen' }<span uk-icon={ !expandedDescriptions.includes(newsItem.id) ? 'chevron-down' : 'chevron-up' }></span></a>
                                                </div>
                                                }
                                            </div>

                                        </div>

                                        {/* column 1-2 */}
                                        <div class="b4u-item-column-1-2 uk-flex-first uk-flex-last@s uk-width-1-4@s">

                                            {/* Logo oder Plakat */}
                                            { getNewsItemImages(newsItem.images, newsItem.content.length > 600, expandedDescriptions.includes(newsItem.id)).map(image => (
                                            <div class="b4u-image-expandable uk-inline uk-transition-toggle" uk-lightbox="true">
                                                <a href={ image.url } data-type="image" style={{ display: 'inline-block', paddingBottom: '15px' }}>
                                                    <img src={ `${image.url}?size=512` } alt={ image.description } />
                                                    {/* nur wenn vorhanden */}
                                                    { image.author &&
                                                    <div class="copy">Foto: { image.author }</div>
                                                    }
                                                    {/* Lupe */}
                                                    <div class="uk-position-center uk-transition-fade uk-light">
                                                        <span uk-icon="icon: search; ratio: 2"></span>
                                                    </div>
                                                </a>
                                            </div>
                                            ))}

                                        </div>

                                    </div>
                                </div>

                            </div>
                        </div>
                        ))}
                        {/* news item ende */}

                    </div>
                    {/* **************************** b4u-newslist.php ENDE **************************** */}
                </li>

                <li id="Kontakt" className={ activeTab === 'Kontakt' ? 'uk-active' : '' }>
                    {/* **************************** b4u-kontakt.php **************************** */}
                    <div class="b4u-contact uk-background-muted uk-padding">

                        {/* contact */}
                        <div class="b4u-main-item">
                            <h1 class="b4u-main-item-name">{ data.name }</h1>
                            <p class="b4u-main-item-ort">{ data.zipcode } { data.city }</p>

                            {/* Socialmedia und Kontak-Links */}
                            <ul class="b4u-main-item-icons">
                                { data.phoneNumber &&
                                <li>
                                    <a class="" href={ `tel:${data.phoneNumber}` } uk-tooltip="anrufen">
                                        <span uk-icon="receiver" class="uk-icon uk-margin-small-right"></span><span>{ data.phoneNumber }</span>
                                    </a>
                                </li>
                                }
                                { data.homepage &&
                                <li>
                                    <a class="" href={ data.homepage } uk-tooltip="zur Homepage">
                                        <span uk-icon="world" class="uk-icon uk-margin-small-right"></span><span>{ data.homepage }</span>
                                    </a>
                                </li>
                                }
                                { data.emailAddress &&
                                <li>
                                    <a class="" href={ `mailto:${data.emailAddress}` } uk-tooltip="E-Mail">
                                        <span uk-icon="mail" class="uk-icon uk-margin-small-right"></span><span>{ data.emailAddress }</span>
                                    </a>
                                </li>
                                }
                                <li>
                                    <a 
                                        href={ `https://blasmusik4u.de/kontakt/?verein=${data.clubId}` } 
                                        uk-tooltip={`Kontakt${ data.emailAddress ? `&#058; ${data.emailAddress}` : ''}`}
                                        title="" aria-expanded="false"
                                    >
                                        <span uk-icon="mail" class="uk-icon uk-margin-small-right"></span><span>zum Kontaktformular</span>
                                    </a>
                                </li>
                                { data.instagram &&
                                
                                <li>
                                    <a class="" href={ getSocialMediaLink(data.instagram, 'instagram') } target="_blank" rel="noopener noreferrer" uk-tooltip="Instagram">
                                        <span uk-icon="instagram" class="uk-icon uk-margin-small-right"></span><span class="uk-hidden@s">Instagram</span><span class="uk-visible@s">{ getSocialMediaLink(data.instagram, 'instagram') }</span>
                                    </a>
                                </li>
                                }
                                { data.facebook &&
                                <li>
                                    <a class="" href={ getSocialMediaLink(data.facebook, 'facebook') } target="_blank" rel="noopener noreferrer" uk-tooltip="Facebook">
                                        <span uk-icon="facebook" class="uk-icon uk-margin-small-right"></span><span class="uk-hidden@s">facebook</span><span class="uk-visible@s">{ getSocialMediaLink(data.facebook, 'facebook') }</span>
                                    </a>
                                </li>
                                }

                                { data.twitter &&
                                <li>
                                    <a class="" href={ getSocialMediaLink(data.twitter, 'twitter') } target="_blank" rel="noopener noreferrer" uk-tooltip="Twitter">
                                        <span uk-icon="twitter" class="uk-icon uk-margin-small-right"></span><span class="uk-hidden@s">twitter</span><span class="uk-visible@s">{ getSocialMediaLink(data.twitter, 'twitter') }</span>
                                    </a>
                                </li>
                                }
                            </ul>

                        </div>

                        {/* Adresse-Kontakt */}
                        { data.chairmanAddress && (data.chairmanAddress.emailaddress ||  data.chairmanAddress.lastName) &&
                        <div class="b4u-kontakt-verein">
                            <h2>Vorsitzende:r</h2>
                            <div>
                                <span class="name"><strong>{ data.chairmanAddress.firstName } { data.chairmanAddress.lastName }</strong></span>
                                <span class="street">{ data.chairmanAddress.street }</span>
                                <span class="city">{ data.chairmanAddress.zipcode } { data.chairmanAddress.city }</span>
                                <span class="phonenumber"><a href={ `tel:${data.chairmanAddress.phonenumber}` }>{ data.chairmanAddress.phonenumber }</a></span>
                                <span class="emailaddress"><a href={ `mailto:${data.chairmanAddress.emailaddress}` }>{ data.chairmanAddress.emailaddress }</a></span>
                            </div>
                        </div>
                        }

                    </div>
                    {/* **************************** b4u-kontakt.php ENDE **************************** */}
                </li>
            </ul>
        </>
    );
};

export default DetailPage;